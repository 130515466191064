import { property } from 'lodash';
import { utils } from '../utils';
import { PG_APP_DEFINITION_ID, GALLERY_WIDGET_ID } from '../constants';
import * as VideoGallerySDK from '@wix/video-gallery-sdk';
import AppSettings from '@wix/photography-client-lib/dist/src/api/appSettings';
import Consts from '@wix/photography-client-lib/dist/src/utils/consts';
import { serverItemsToProGallery } from '@wix/pro-gallery-items-formatter/dist/src/server';
import { experimentsWrapper } from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import 'whatwg-fetch';

export default class ProGalleryStore {
  constructor(context, wixCodeApi, platformServices, scopedGlobalSdkApis) {
    this.context = context;
    this.wixCodeApi = wixCodeApi;
    this.getGalleryDataFromServer = this.getGalleryDataFromServer.bind(this);
    this.getItemsFromServer = this.getItemsFromServer.bind(this);
    this.setItems = this.setItems.bind(this);
    this.getItems = this.getItems.bind(this);
    this.onItemClicked = this.onItemClicked.bind(this);
    this.setPageName = this.setPageName.bind(this);
    this.formatServerGalleryResponse = this.formatServerGalleryResponse.bind(
      this,
    );
    this.verifyExperiments = this.verifyExperiments.bind(this);
    this.baseUrl = ProGalleryStore.getBaseUrl(this.wixCodeApi);
    this.appSettings = new AppSettings(
      this.context.compId,
      this.context.instance,
      this.baseUrl,
    );
    this.scopedGlobalSdkApis = scopedGlobalSdkApis;
    this.pgRenderStart = Date.now();

    try {
      this.createFedopsLogger(platformServices);
      this.fedopsLogger.appLoadStarted();
      this.isStartReported = true;
      console.log('pro-gallery-santa-wrapper started to load');
    } catch (e) {
      console.error('Cannot create fedops logger', e);
      this.fedopsLogger = {
        appLoaded: () => {},
        appLoadStarted: () => {},
      };
    }
  }

  createFedopsLogger = platformServices => {
    const fedOpsLoggerFactory = platformServices.fedOpsLoggerFactory;
    this.fedopsLogger = fedOpsLoggerFactory.getLoggerForWidget({
      appId: PG_APP_DEFINITION_ID,
      widgetId: GALLERY_WIDGET_ID,
    });
  };

  reportAppLoaded = () => {
    try {
      if (this.isStartReported && !this.appLoadedReported) {
        this.fedopsLogger.appLoaded();
        this.appLoadedReported = true;
        console.log('pro-gallery-santa-wrapper loaded');
      }
    } catch (e) {
      if (utils.isVerbose()) {
        console.log('cant run fedopsLogger.appLoaded()');
      }
    }
  };

  static getBaseUrl(api) {
    const baseUrl = api.location.baseUrl;
    const baseUrlParts = baseUrl.split('/');
    const origin = baseUrlParts.slice(0, 3).join('/');
    return origin;
  }

  static getSiteUrl(api) {
    return api.location.baseUrl;
  }

  static getPageUrl(api) {
    const baseUrl = api.location.baseUrl;
    const currentPageUrl = api.site.currentPage ? api.site.currentPage.url : '';
    const url = baseUrl + currentPageUrl;
    return url;
  }
  static getExperiments(baseApi) {
    // only under scope `pro-gallery-viewer`
    const baseUrl = ProGalleryStore.getBaseUrl(baseApi);
    return fetch(`${baseUrl}/_api/pro-gallery-webapp/v1/viewer/experiments`, {
      credentials: 'include',
    })
      .then(res => res.json())
      .then(res => res.experiments)
      .catch(() => {
        return {};
      });
  }

  static getTranslations() {
    // only under scope `pro-gallery-viewer`
    return fetch(
      'https://static.parastorage.com/services/pro-gallery-statics/2.854.0/assets/locale/pro-gallery/Gallery/Gallery_en.json',
    )
      .then(res => res.json())
      .catch(() => {
        return {};
      });
  }

  async getSettings() {
    try {
      const settings = await this.appSettings.get(
        Consts.dataSavedState.PUBLISHED,
      );
      return settings;
    } catch (error) {
      console.error('Failed to get settings: ' + error);
    }
    return undefined;
  }

  formatServerGalleryResponse(serverGalleryResponseData, settings) {
    const gallery =
      serverGalleryResponseData && serverGalleryResponseData.gallery;
    return gallery && settings
      ? {
          dateCreated: gallery.created,
          galleryId: gallery.id,
          pageResponse: {
            totalItemsCount: gallery.totalItemsCount,
            settings: JSON.stringify(settings),
            items: this.setPageName(serverItemsToProGallery(gallery.items)),
          },
        }
      : {};
  }

  setPageName(proGalleryItems) {
    const sitePages = this.wixCodeApi.site.getSiteStructure().pages;
    return proGalleryItems.map(item => {
      try {
        if (
          'metaData' in item &&
          'link' in item.metaData &&
          'data' in item.metaData.link &&
          'type' in item.metaData.link.data &&
          item.metaData.link.data.type === 'PageLink'
        ) {
          const page = sitePages.find(page =>
            item.metaData.link.data.pageId.endsWith(page.id),
          );
          if (!!page && 'name' in page) {
            item.metaData.link.data.pageName = page.name;
          }
        }
      } catch (error) {}
      return item;
    });
  }

  async verifyExperiments() {
    if (
      !experimentsWrapper.getExperiments() ||
      Object.keys(experimentsWrapper.getExperiments()).length === 0
    ) {
      const experimentsRaw = await ProGalleryStore.getExperiments(
        this.scopedGlobalSdkApis,
      );
      experimentsWrapper.setExperiments(experimentsRaw);
    }
  }

  async getGalleryDataFromServer(from, options = {}) {
    const { isSSR = false } = options;
    const to = from + (from > 0 ? 100 : 50); //start with 50 items
    const {
      externalId = '00000000-0000-0000-0000-000000000000',
      compId,
      instanceId,
      instance,
    } = this.context;
    await this.verifyExperiments(); // delete when 'specs.pro-gallery.appSettings' is merged - no need to get experiments
    if (
      experimentsWrapper.getExperimentBoolean('specs.pro-gallery.appSettings')
    ) {
      const settings = await this.getSettings();
      const limit = to - from;
      const itemsUrl =
        settings &&
        settings.galleryId &&
        `${this.baseUrl}/pro-gallery-webapp/v1/galleries/${settings.galleryId}?offset=${from}&limit=${limit}&externalId=${externalId}`;
      if (itemsUrl) {
        try {
          const response = await fetch(itemsUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: instance,
            },
          });
          const data = await response.json();
          return this.formatServerGalleryResponse(data, settings);
        } catch (error) {}
      }
    }
    const itemsUrl = this.addSsrUrlParams(
      isSSR,
      `${this.baseUrl}/_api/pro-gallery-webapp/v1/gallery/${instanceId}/items/from/${from}/to/${to}?compId=${compId}&externalId=${externalId}`,
    );

    if (utils.isVerbose()) {
      console.log('Getting items from server', from, to, itemsUrl);
    }
    return fetch(itemsUrl)
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404 && isSSR) {
          if (utils.isVerbose()) {
            console.log('first publish');
          }
          return [];
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch(e => {
        console.error('Could not get items from server', e);
        return [];
      });
  }

  async getItemByIdFromServer(itemId, options = {}) {
    const { isSSR = false } = options;
    const baseUrl = ProGalleryStore.getBaseUrl(this.wixCodeApi);
    const { instance } = this.context;

    const itemsUrl = `${baseUrl}/pro-gallery-webapp/v1/galleries/${this.galleryId}/items/${itemId}`;
    return fetch(itemsUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: instance,
      },
    })
      .then(res => {
        if (res.status === 200) {
          return res.json();
        } else if (res.status === 404 && isSSR) {
          return [];
        } else {
          throw new Error(res.statusText);
        }
      })
      .catch(e => {
        console.error('Could not get item by id from the server', e);
        return [];
      });
  }

  getItemsFromServer(from) {
    return this.getGalleryDataFromServer(from).then(res => {
      return res.pageResponse.items;
    });
  }
  NavigateToLinkIfNeeded(itemData, itemClickStyleParam) {
    if (itemClickStyleParam !== 'link') {
      return;
    } else {
      this.navigateToLink(itemData);
    }
  }

  addSsrUrlParams(isSSR, itemsUrl) {
    if (isSSR) {
      itemsUrl += '&petri_ovr=specs.SkipCachePopulationSpec:true';
    }
    return itemsUrl;
  }

  navigateToLink(itemData) {
    const link = property('dto.metaData.link')(itemData);
    try {
      if (link) {
        if (link.type === 'web') {
          this.wixCodeApi.location.to(link.url);
        } else if (link.type === 'page') {
          try {
            const pageUrl = this.wixCodeApi.site
              .getSiteStructure()
              .pages.find(page => page.id === link.url).url;
            this.wixCodeApi.location.to(pageUrl);
          } catch (e) {
            console.warn('Cannot navigate to page', e);
          }
        } else if (link.type === 'wix') {
          const linkData = property('dto.metaData.link.data')(itemData);
          if (linkData) {
            const externalUrl = this.wixCodeApi.location.getExternalUrl(
              linkData,
            );
            if (!externalUrl) {
              this.wixCodeApi.location.navigateTo(linkData);
            } else {
              console.error('this should have been handled by <a href>');
            }
          }
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  onItemClicked(itemData, itemClickStyleParam) {
    this.NavigateToLinkIfNeeded(itemData, itemClickStyleParam);
  }

  getItemsWithWixVideosUrls(items) {
    // const itemsPromise = new Promise((resolve, reject) => {
    const result = items.map(item => {
      const meta = item.metaData;
      if (meta.videoUrl && meta.videoUrl.includes('wix:video://')) {
        const videoId = meta.videoUrl.split('/')[3] || item.itemId;
        return VideoGallerySDK.getVideoURLs(videoId).then(qualities => {
          item.metaData.qualities = qualities
            .filter(quality => quality.type === 'MP4')
            .map(qualityObject => {
              const url = qualityObject.url;
              const quality = qualityObject.quality;
              const height = parseInt(quality);
              const width = Math.floor(
                (height * item.metaData.width) / item.metaData.height,
              );
              return {
                url,
                height,
                width,
                quality,
                formats: ['mp4'],
              };
            });
          item.metaData.videoUrl = '';
          return item;
        });
      } else {
        return Promise.resolve(item);
      }
    });

    return Promise.all(result);
  }

  getItemsWithoutWixVideos() {
    const itemsWOVideos = this.getItems().map(item => {
      return item.metaData.videoUrl &&
        item.metaData.videoUrl.includes('wix:video://')
        ? //wix video => replace with image until src is ready
          {
            itemId: item.itemId + '_placeholder',
            mediaUrl: item.metaData.posters[0].url,
            orderIndex: 0,
            metaData: {
              ...item.metaData,
              type: 'image',
              height: item.metaData.posters[0].height,
              width: item.metaData.posters[0].width,
            },
          }
        : item;
    });
    return itemsWOVideos;
  }

  setItems(items) {
    if (items && items.length >= 0) {
      this.items = items.map(item => {
        let directLink = {
          url: undefined,
          target: undefined,
        };
        if (item.metaData && item.metaData.link) {
          const link = item.metaData.link;
          if (link.type === 'web' && typeof link.url === 'string') {
            const isExternal =
              link.url.slice(0, 4) === 'http' || link.url.slice(0, 2) === '//';
            if (isExternal) {
              directLink = {
                url: link.url,
                target: '_blank',
              };
            }
          } else if (link.type === 'wix') {
            const linkData = link.data;
            if (linkData) {
              try {
                const externalUrl = this.wixCodeApi.location.getExternalUrl(
                  linkData,
                );
                if (externalUrl) {
                  directLink = {
                    url: externalUrl,
                    target: linkData.target || '_self',
                  };
                } else if (
                  !!linkData.pageName &&
                  linkData.target === '_blank' &&
                  linkData.type === 'PageLink'
                ) {
                  const pageUrl = this.wixCodeApi.site
                    .getSiteStructure()
                    .pages.find(page => page.name === linkData.pageName);
                  const url =
                    pageUrl &&
                    pageUrl.url &&
                    this.wixCodeApi.location.baseUrl &&
                    this.wixCodeApi.location.baseUrl + pageUrl.url;
                  directLink = !!url
                    ? {
                        url: url,
                        target: '_blank',
                      }
                    : directLink;
                }
              } catch (e) {
                console.error(e);
              }
            }
          }
        }
        item.directLink = directLink;
        return item;
      });
    } else {
      console.error('corrupt items', items, 'returning old items instead');
      return this.items;
    }
  }

  getItems() {
    return this.items || [];
  }

  addItems(items, from) {
    const curItems = this.getItems();
    this.setItems(curItems.slice(0, from).concat(items)); //do not double append items
  }

  loadInitialItems(isSSR) {
    if (utils.isVerbose()) {
      console.log(
        'Getting initial items from server',
        this.items,
        this.totalItemsCount,
      );
    }
    if (this.items && this.items.length > 0) {
      //items were already loaded by wix-code or warmup data
      return Promise.resolve(this.items);
    } else {
      let itemsPromise;
      try {
        itemsPromise = this.getGalleryDataFromServer(0, { isSSR });
      } catch (e) {
        console.error('couldnt get items', e);
        itemsPromise = Promise.resolve({
          pageResponse: {
            totalItemsCount: 0,
            items: [],
          },
        });
      }

      return itemsPromise.then(response => {
        if (utils.isVerbose()) {
          console.log('Got initial items from server', response);
        }
        try {
          const { galleryId, dateCreated } = response;
          const { items, settings, totalItemsCount } = response.pageResponse;
          Object.assign(this, { galleryId, dateCreated, settings }); //parse everything
          this.setItems(items);
          this.totalItemsCount = Number(totalItemsCount);
        } catch (e) {
          console.error(
            'Could not fetch initial items from server',
            e,
            response,
          );
          this.totalItemsCount = 1;
        }
        return this.getItems();
      });
    }
  }

  pageReady(setProps) {
    this.setProps = setProps;
  }
}
